import { useApi } from '~/composables/api/useApi'

export function useNotifications() {
  const { fetchWithAuth } = useApi()
  const runtimeConfig = useRuntimeConfig()

  const notificationsAPI = runtimeConfig.public.notificationsAPI

  async function createNotification(providedOptions: { notification: object }) {
    return await fetchWithAuth(`${notificationsAPI}/notifications`, 'POST', {
      data: providedOptions.notification,
    })
  }

  async function markAsRead(providedOptions: { id: number }) {
    return await fetchWithAuth(
      `${notificationsAPI}/notifications/${providedOptions.id}/read`,
      'PUT'
    )
  }

  interface GetNotificationOptions {
    limit?: number
    offset?: number
  }

  async function getAllNotifications(options: GetNotificationOptions) {
    return [
      {
        id: 22,
        userId: 88244,
        type: 'Your Upload is complete!',
        message: '',
        isRead: false,
        createdAt: '2025-02-11T16:42:10.833Z',
        updatedAt: '2025-02-11T16:42:10.833Z',
      },
      {
        id: 21,
        userId: 88244,
        type: 'Your Upload is complete!',
        message: '',
        isRead: false,
        createdAt: '2025-02-11T16:42:10.668Z',
        updatedAt: '2025-02-11T16:42:10.668Z',
      },
      {
        id: 20,
        userId: 88244,
        type: 'Your Upload is complete!',
        message: '',
        isRead: true,
        createdAt: '2025-02-11T16:41:51.240Z',
        updatedAt: '2025-02-11T16:41:58.530Z',
      },
      {
        id: 19,
        userId: 88244,
        type: 'Your Upload is complete!',
        message: '',
        isRead: true,
        createdAt: '2025-02-11T16:41:50.894Z',
        updatedAt: '2025-02-11T16:41:59.819Z',
      },
      {
        id: 18,
        userId: 88244,
        type: 'Your Upload is complete!',
        message: '',
        isRead: true,
        createdAt: '2025-02-11T16:41:50.685Z',
        updatedAt: '2025-02-11T16:42:02.285Z',
      },
      {
        id: 17,
        userId: 88244,
        type: 'Your Upload is complete!',
        message: '',
        isRead: true,
        createdAt: '2025-02-11T16:41:50.422Z',
        updatedAt: '2025-02-11T16:41:56.797Z',
      },
      {
        id: 16,
        userId: 88244,
        type: 'Your Upload is complete!',
        message: '',
        isRead: true,
        createdAt: '2025-02-11T16:41:49.905Z',
        updatedAt: '2025-02-11T16:41:55.442Z',
      },
      {
        id: 15,
        userId: 88244,
        type: 'Your Upload is complete!',
        message: '',
        isRead: true,
        createdAt: '2025-02-11T16:37:06.330Z',
        updatedAt: '2025-02-11T16:41:37.364Z',
      },
    ]
    return await fetchWithAuth(`${notificationsAPI}/notifications`, 'GET', {
      providedOptions: options,
    })
  }

  return {
    createNotification,
    getAllNotifications,
    markAsRead,
  }
}
