<template>
  <TransitionRoot :show="open" as="template" @after-leave="query = ''" appear>
    <Dialog :open="open" @close="closeDialog" class="relative z-50">
      <!-- Gray Background Not sure if we want that-->
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/25 transition-opacity" />
      </TransitionChild>

      <!-- Main visible Dialog -->
      <div
        role="dialog"
        class="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-40"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel
            role="search"
            class="mx-auto max-w-[640px] transform overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black/5 transition-all"
          >
            <Combobox v-model="selected" nullable>
              <div class="grid grid-cols-1">
                <ComboboxInput
                  class="col-start-1 row-start-1 h-12 w-full pl-11 pr-4 md:text-base sm:text-sm text-primaryG-700 outline-none placeholder:text-secondaryG-700"
                  placeholder="Search..."
                  @change="updateQuery"
                />

                <i
                  class="mdi mdi-magnify col-start-1 row-start-1 ml-4 text-xl self-center text-gray-400 pointer-events-none"
                ></i>
              </div>

              <div>
                <div class="block">
                  <nav
                    class="flex px-4 py-2 space-x-1 overflow-x-auto"
                    aria-label="Tabs"
                  >
                    <button
                      v-for="tab in tabs"
                      :key="tab"
                      :class="[
                        tab === currentTab
                          ? 'bg-primary text-white'
                          : 'bg-ui-lightest text-primary hover:text-white hover:bg-primaryG-500',
                        'rounded-[4px] px-1.5 py-0.5 text-sm font-strong cursor-pointer whitespace-nowrap',
                      ]"
                      :aria-current="tab ? 'tab' : undefined"
                      @click="selectTab(tab)"
                    >
                      {{ tab }} ({{ getResultNum(tab) }})
                    </button>
                  </nav>
                </div>
              </div>

              <div
                v-if="query === ''"
                class="border-t border-gray-100 px-6 py-8 text-center text-sm sm:px-14"
              >
                <p class="font-semibold text-gray-900">
                  {{ $t('Search for collections and documents') }}
                </p>
                <router-link
                  to="/search"
                  class="text-blue-500 hover:underline"
                  @click="closeDialog"
                >
                  For fulltext search go here
                </router-link>
              </div>

              <div
                v-else-if="
                  !isLoadingColls &&
                  (collections.length > 0 || documents.length > 0)
                "
              >
                <ComboboxOptions static class="-space-y-[1em]">
                  <li
                    v-if="currentTab === 'All' || currentTab === 'Collections'"
                  >
                    <QuickSearchResults
                      :elements="filteredCollections"
                      name="Collections"
                      @selectTab="selectTab"
                      @onSelect="onSelect"
                      :currentTab="currentTab"
                    />
                  </li>

                  <li v-if="currentTab === 'All' || currentTab === 'Documents'">
                    <QuickSearchResults
                      :elements="filteredDocuments"
                      name="Documents"
                      @selectTab="selectTab"
                      @onSelect="onSelect"
                      :currentTab="currentTab"
                    />
                  </li>

                  <li v-if="currentTab === 'All' || currentTab === 'Full text'">
                    <QuickSearchResults
                      :elements="filteredFulltextRes"
                      name="Full text"
                      @selectTab="selectTab"
                      @onSelect="onSelect"
                      :currentTab="currentTab"
                    />
                  </li>

                  <li v-if="(currentTab === 'All' || currentTab === 'Files') && isInDocument">
                    <QuickSearchResults
                      :elements="filteredFiles"
                      name="Files"
                      @selectTab="selectTab"
                      @onSelect="onSelect"
                      :currentTab="currentTab"
                    />
                  </li>
                </ComboboxOptions>
              </div>

              <!-- No results-->
              <div
                v-else-if="
                  !isLoadingColls &&
                  collections.length === 0 &&
                  documents.length === 0
                "
                class="border-t border-gray-100 px-6 py-8 text-center text-sm sm:px-14"
              >
                <p class="font-semibold text-gray-900 text-balance">
                  {{
                    $t(
                      'No collections or documents found. Did you mean to search the fulltext for'
                    )
                  }}
                  "{{ query }}"?
                </p>
                <router-link
                  :to="`/search?term=${query}`"
                  class="text-blue-500 hover:underline"
                  @click="closeDialog"
                >
                  Search fulltext
                </router-link>
              </div>
            </Combobox>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { defineEmits, defineProps, watch } from 'vue'
import { useRoute } from 'vue-router'

import {
  Dialog,
  DialogPanel,
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'

import { debounce } from '~/utils/misc.js'
import QuickSearchResults from './QuickSearchResults.vue'

let tabs = ref([
  'All',
  'Collections',
  'Documents',
  // 'Files'
  //  'Models',
  //  'Sites',
  'Full text',
])
const currentTab = ref('All')

const { $bus, $sentry, $router } = useNuxtApp()
const { t } = useI18n()

const transformComp = useTransform()
const searchApi = useSearch()
const collectionsApi = useCollections()
const route = useRoute()

const query = ref('')
const isLoadingColls = ref(false)
const isLoadingDocuments = ref(false)
const isLoadingFulltextRes = ref(false)
const isLoadingFiles = ref(false)

const collections = ref([])
const documents = ref([])
const fulltextRes = ref([])
const files = ref([])
const selected = ref(null)

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
})

watch(
  () => props.open,
  newVal => {
    if (newVal) {
      let collectionid = route.params.collectionid
      let docId = route.params.docid
      console.log("open", collectionid, docId, route.params)
      if (collectionid && docId) {
        tabs.value = ['All', 'Collections', 'Documents', 'Files', 'Full text']
      } else {
        tabs.value = ['All', 'Collections', 'Documents', 'Full text']
      }
    }
  }
)

const emit = defineEmits(['close'])
const closeDialog = () => {
  collections.value = []
  documents.value = []
  fulltextRes.value = []
  emit('close', false)
}

const selectTab = tab => {
  currentTab.value = tab
}

const getResultNum = tab => {
  switch (tab) {
    case 'Collections':
      return collections ? collections.value.length : 0
    case 'Documents':
      return documents ? documents.value.length : 0
    case 'Full text':
      return fulltextRes ? fulltextRes.value.length : 0
    case 'Files':
      return files ? files.value.length : 0
    case 'All':
      if (!collections.value && !documents.value && !fulltextRes.value && !files.value) return 0

      return collections.value.length + documents.value.length + fulltextRes.value.length + files.value.length
    default:
      return 0
  }
}

const onSelect = (value, resultType) => {
  selected.value = value
  console.log("onSelect", value, resultType)

  let url = ''
  switch (resultType) {
    case 'Files':
      const collId = route.params.collectionid
      const docId = route.params.docid
      const pageId = value.pageNr
      url = `/collection/${collId}/doc/${docId}/edit?pageid=${pageId}`
      break
    case 'Documents':
      url = `/collection/${value.mainColId}/doc/${value.docId}`
      break
    case 'Collections':
      url = `/collection/${value.colId}`
      break
    case 'Full text':
      const fullTextCollId = value.meta.collections[0]
      const fullTextDocId = value.id.document
      const fullTextPageId = value.id.page
      url = `/collection/${fullTextCollId}/doc/${fullTextDocId}/edit?pageid=${fullTextPageId}`
      break
    default:
      $bus.emit('notification', {
        message: t('Could not forward to search result.'),
        type: 'error',
      })
      $sentry.captureMessage(
        `Could not open search result for value: ${JSON.stringify(
          value
        )}, search term: ${query.value}`
      )
      return
  }

  if (url.startsWith('/')) {
    $router.push(url)
  } else {
    window.location.href = url
  }
  closeDialog()
}

const canSubmit = value => {
  return typeof value === 'string' && value !== '' && value.length > 2
}

const filteredCollections = computed(() => {
  if (currentTab.value === 'All') {
    return collections.value.slice(0, 3)
  }
  return collections.value
})

const filteredDocuments = computed(() => {
  if (currentTab.value === 'All') {
    return documents.value.slice(0, 3)
  }
  return documents.value
})

const filteredFulltextRes = computed(() => {
  if (currentTab.value === 'All') {
    return fulltextRes.value.slice(0, 3)
  }
  return fulltextRes.value
})

const filteredFiles = computed(() => {
  if (currentTab.value === 'All') {
    return files.value.slice(0, 3)
  }
  return files.value
})

const updateQuery = e => {
  selected.value = null
  query.value = e.target.value
  debouncedUpdateQuery()

  if (query.value === '') {
    collections.value = []
    documents.value = []
    fulltextRes.value = []
    files.value = []
  }
}

const debouncedUpdateQuery = debounce(() => {
  if (canSubmit(query.value)) {
    getFilteredCollection(query.value)
    getFilteredDocuments(query.value)
    getFullTextResults(query.value)
    getFiles(query.value)
  }
}, 1000)

const getFilteredCollection = async query => {
  isLoadingColls.value = true
  let data
  try {
    data = await collectionsApi.filterCollections({
      offset: 0,
      filter: query,
    })

    collections.value = data.trpCollection.reverse()
    isLoadingColls.value = false
  } catch (err) {
    $sentry.captureException(err)
    isLoadingColls.value = false
    return
  }
}

const getFilteredDocuments = async query => {
  isLoadingDocuments.value = true
  let data
  try {
    data = await collectionsApi.findDocuments({
      title: query,
    })

    documents.value = data
    isLoadingDocuments.value = false
  } catch (err) {
    $sentry.captureException(err)
    isLoadingDocuments.value = false
    return
  }
}

const getFullTextResults = async query => {
  isLoadingFulltextRes.value = true
  let data
  try {
    data = await searchApi.fetchSearchFulltext({
      term: query + '~0', // ~0 to search for exact match; fuzziness=0
      start: 0,
      rows: 100,
    })
    let transformData = transformComp.transform(data)
    fulltextRes.value = transformData.items
    isLoadingFulltextRes.value = false
  } catch (err) {
    $sentry.captureException(err)
    isLoadingFulltextRes.value = false
    return
  }
}

const isInDocument = computed(() => {
  let collectionid = route.params.collectionid
  let docId = route.params.docid
  return collectionid && docId
})

const getFiles = async query => {
  let collectionid = route.params.collectionid
  let docId = route.params.docid
  if (!collectionid || !docId) {
    return
  }

  isLoadingFiles.value = true
  let data
  try {
    data = await collectionsApi.fetchPagesString({
      docId: docId,
      collId: collectionid,
      filename: query,
    })
    console.log("files", data)
    files.value = data
    isLoadingFiles.value = false
  } catch (err) {
    $sentry.captureException(err)
    isLoadingFiles.value = false
    return
  }
}
</script>
